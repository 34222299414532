import React from "react"

import qrImage from "../../assets/images/qr-code.png"

import "./Qr.scss"

const Qr = () => {
  return <img src={qrImage} className="qr-code" />
}

export default Qr
