import React from "react"

import { navigate } from "gatsby"

import StarLight from "../../assets/images/Icons/Star-light.svg"
import Star from "../../assets/images/Icons/Star.svg"
import LogoLight from "../../assets/images/Logo-light.svg"
import Logo from "../../assets/images/Logo.svg"
import Link from "../../utils/link"
import Qr from "./Qr"

import "./ScreenSaver.scss"

const ScreenSaver = () => {
  const places = [
    `256 Arden Street <br />
              Coogee NSW 2034`,
    `110 Avoca Street <br />
              Randwick NSW 2031`,
    `R1107/488 Pacific Hwy <br />
              St Leonards sq.`,
    `65A Macpherson Street <br />
              Waverley NSW 2024`,
  ]

  return (
    <div
      className="screen-saver"
      onClick={() => {
        navigate("/login")
      }}
    >
      <div className="screen-saver__top">
        <div className="screen-saver__container">
          <div className="screen-saver__top__first-row">
            <img src={LogoLight} className="screen-saver__top__logo" />

            <p className="screen-saver__text screen-saver__top__first-row__heading">
              Self service laundromat
            </p>

            <img src={Star} className="screen-saver__top__first-row__star" />

            <p className="screen-saver__text screen-saver__top__first-row__text">
              <img src={StarLight} className="screen-saver__text__star" />
              DROP OFF PICK UP
            </p>

            <img src={Star} className="screen-saver__top__first-row__star" />

            <p className="screen-saver__text screen-saver__top__first-row__text">
              <img src={StarLight} className="screen-saver__text__star" />
              IN APP PAY AND GO
            </p>
          </div>

          <div className="screen-saver__top__second-row">
            <p className="landing-new__text">7 days / 7am — 11pm</p>
            <Link to="https://www.instagram.com/washroom_laundry/">
              <p className="landing-new__text">@washroom_laundry</p>
            </Link>
            <Link to="/pricing">
              <p className="landing-new__text">Price List</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="screen-saver__middle">
        <div className="screen-saver__container">
          <img src={Logo} className="screen-saver__middle__logo" />

          <div className="screen-saver__middle__grid">
            {places.map((place, index) => {
              return (
                <p
                  className="screen-saver__text"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: place }}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="screen-saver__bottom">
        <div className="screen-saver__container">
          <div className="screen-saver__bottom__mob">
            <p className="landing__text">
              <Link to="https://www.instagram.com/washroom_laundry/">
                @washroom_laundry
              </Link>
            </p>
            <div className="screen-saver__bottom__mob__flex">
              <p className="screen-saver__text">7 days / 7am — 11pm</p>
              <img
                src={StarLight}
                className="screen-saver__text__star"
                style={{ margin: 0 }}
              />
            </div>
            <Link to="/pricing">
              <p className="landing-new__text">Price List</p>
            </Link>
          </div>

          <p className="screen-saver__bottom__click">
            We’re taking a break. Tap anywhere to start
          </p>
        </div>
        <div className="screen-saver__bottom__qr">
          <Qr />
        </div>
      </div>
    </div>
  )
}

export default ScreenSaver
