import React from "react"

import ScreenSaver from "../components/LoginRegister/ScreenSaver"

const GetStarted = () => {
  return (
    <div>
      <ScreenSaver />
    </div>
  )
}

export default GetStarted
